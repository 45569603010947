.whole{
    background-image: url('../../../assets/images/HomeImages/Bg4.png');
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    width: 100vw;
    max-width: 100%;
    padding-top: 188px;
    padding-bottom: 100px;
}

.inside{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.title{
    margin-bottom: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #93C8BF;
}
.text{
    margin-top: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #74738E;
}

.videoDiv{
    padding: 24px;
    border-radius: 16px;
    /*opacity: 0.5;*/
    background:#EAEAEE;
}