.inside{
    padding-bottom:28px;
    padding-top: 48px;
    background-image: url('../../../assets/images/ServiceImages/Haute8.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.subTitle{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #595878;
    margin-top: 38px;
    text-align: center;
}
.boldText{
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #595878;
    margin-left: 5px;
}
.text{
    max-width:386px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #595D67;
    margin:12px auto 0;
}
.slideInside{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.slide{
    max-width: 30%;
    /*max-width: 251px;*/
    width: 100%;
    min-height: 357px;
}
.bigSlide{
    max-width: 30%;
    /*max-width: 289px;*/
    width: 100%;
    min-height: 460px;
}

.name{
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    padding-bottom: 2px;
    border-bottom: 4px solid #B5E3DB;;
    color:#302E56;
    text-align: center;
    max-width: 146px;
    width: 100%;
}

.imgDiv{
    max-width: 251px;
    width: 100%;
    height: 299px;
}

.bigImgDiv{
    max-width: 289px;
    width: 100%;
    height: 378px;
}