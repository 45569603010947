.whole{
    padding-top: 120px;
    padding-bottom: 120px;
}

.title{
    margin-bottom: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #93C8BF;
}

.inside{
    height: 320px;
    position: relative;
}