.whole{
    padding-top: 70px;
    padding-bottom: 70px;
    background-image: url('../../../assets/images/ServiceImages/Services10.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100vw;
    max-width: 100%;
    margin-bottom: 120px;
}

.inside{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.title{
    margin-bottom: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #93C8BF;
}
.text{
    margin-top: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #74738E;
}

.videoDiv{
    padding: 24px;
    border-radius: 16px;
    /*opacity: 0.5;*/
    background:#EAEAEE;
}

.right{
    width:40%
}