.logo{
    margin-right: 60px;
    width: 140px;
    height: 30px;
    cursor: pointer;
}
.left{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.language{
    padding: 6px 12px;
    border-radius: 8px;
    border: 1px solid  #EAEAEE;
    background-color: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(48, 53, 65, 0.04);
    display: flex;
    align-items: center;
}
.icon{
    margin-left: 4px;
    cursor: pointer;
}