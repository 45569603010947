.btnStyle{
    background:#302E56;
    border-radius: 12px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Afrika_Regular,sans-serif !important;
    max-width: fit-content;
}
.btnStyle:disabled{
    cursor: not-allowed;
}
.btnStyle:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}

/*@media only screen and (min-width:850px) and (max-width: 1024px) {*/
/*    .btnStyle{*/
/*        line-height: 20px;*/
/*        width: 400px !important;*/
/*    }*/
/*}*/

/*@media only screen and (min-width:768px) and (max-width: 850px) {*/
/*    .btnStyle{*/
/*        line-height: 20px;*/
/*        width:300px !important;*/
/*    }*/
/*}*/
