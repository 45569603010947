.whole{
    padding:30px 62px 30px 52px;
    border-radius: 20px;
    background: linear-gradient(94deg, rgba(48, 46, 86, 0.08) 0%, rgba(48, 46, 86, 0.00) 100%);
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 64px;
}

.left{

}
.text{
    margin-top: 12px;
    margin-bottom: 30px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #595878;
}
.right{
    display: flex;
    align-items: center;
    position: relative;
}
.rightDiv{
    box-shadow: 0px 8px 12px 0px rgba(48, 53, 65, 0.08);
}
.imageStyle{
    max-width:285px;
    width: 100%;
}

.subscribeDiv{
    max-width: 267px;
    width: 100%;
    border-radius: 12px  0px  0px 12px;
    background: #FFF;
    box-shadow: 0px 24px 40px 0px rgba(48, 53, 65, 0.08);
    padding:14px;
    display: flex;
    justify-content: space-between;
}
.subscribe{
    border:none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #595878
}

.subscribe:focus{
    border: none;
    outline: none;
}


.iconDiv{
    padding:12px;
    border-radius: 0px 4px 4px 0px;
    background: #A2DCD2;
    max-width:58px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.iconStyle{
    width: 24px;
    height: 24px;
}

.subscription{
    display: flex;
    align-items: center;
    position: absolute;
    right: 200px;
    width: 100%;
}


