.whole{
    padding-top: 72px;
    padding-bottom: 72px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}
.left{
    width: 30%;
    padding-top: 24px;
    padding-bottom: 24px;
    border-radius: 12px;
    background: #F9FAFA;
    height: fit-content;
    display: flex;
    flex-direction: column;
}
.single{
    padding: 8px 8px 8px 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #A0A2A8;
    border-left: 2px solid #E5E6E7;
    margin-bottom: 10px;
    cursor: pointer;
}
.activeSingle{
    color: #302E56;
    border-left: 2px solid #93C8BF;
}
.right{
    width: 70%;
    padding:24px;
    border-radius: 12px;
    border: 1px solid #E5E6E7;
}
.title{
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    color: #302E56;
    margin-bottom: 20px;
}
.row{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.iconDiv{
    width: 40px;
    height: 40px;
    margin-right: 16px;
}
.iconStyle{
    width: 40px;
    height: 40px;
}
.subTitle{
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #302E56;
}
.text{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #595D67;
    margin-bottom: 12px;
}
ul{
    padding-left: 15px;
}
.listStyle{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 8px;
    color: #595D67;
}