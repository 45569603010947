.whole{
    width: 100vw;
    max-width: 100%;
    margin-bottom: 100px;
}
.inside{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.left{
    max-width: 550px;
    width: 100%;
}

.text{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 18px;
    color:#595D67;
}

.videoDiv{
    padding: 24px;
    border-radius: 16px;
    background:#EAEAEE;
}




