.insideReady{
    padding-bottom:84px;
    padding-top: 50px;
    background-image: url('../../../assets/images/ServiceImages/Ready2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.subTitle{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #595878;
    margin-top: 38px;
    text-align: center;
}
.boldText{
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #595878;
    margin-left: 5px;
}
.text{
    max-width:386px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #595D67;
    margin:12px auto 32px;
}