.title{
    margin-top: 124px;
    margin-bottom: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #93C8BF;
    text-align: center;
}
.inside{
    display: flex;
    justify-content: space-between;
}
.single{
    margin-top: 50px;
    border-radius: 12px;
    border: 1px dashed #BFBECB;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 120px;
}
.singleTitle{
    margin-bottom: 8px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #302E56;
}
.singleText{
    text-align: center;
    max-width:323px;
    width: 100%;
    margin-bottom: 22px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #595D67;
}
.learnBtn{
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #302E56;
    display: flex;
    align-items: center;
}
