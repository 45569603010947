.whole{
    background-image: url('../../../assets/images/HomeImages/BackgroundHome.png');
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    height: 800px;
    width: 100vw;
    max-width: 100%;
    display: flex;
}

.homeText{
    margin-top: 20px;
    margin-bottom: 32px;
    max-width: 478px;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #74738E;
}
.leftPart{
    margin-top: 250px;
    max-width: 478px;
    width: 100%;
}