.whole{
    background-image: url('../../../assets/images/ServiceImages/Services11.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    max-width: 100%;
    height: 791px;
    margin-bottom: 72px;
    padding-left: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title{
    max-width: 590px;
    width:100%;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color:#302E56;
    margin-bottom: 32px
}