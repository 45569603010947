@font-face {
  font-family: Afrika_Regular;
  src: url('app/assets/fonts/aft/Aftika_Regular.otf');
}

* {
  box-sizing: border-box;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  width: 100%;
  font-family: Afrika_Regular,sans-serif !important;
}
header {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color:#FFF;
  box-shadow: 0px 4px 8px 0px rgba(48, 53, 65, 0.04);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.linkStyle{
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color:#232020;
  margin-right: 37px;
  text-decoration: none;
}
.active{
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #93C8BF;
  margin-right: 37px;
}

.linkStyle:hover {
  text-decoration: underline
}

.container{
  max-width: 1710px;
  width: 100%;
  padding-left:15px;
  padding-right: 15px;
  margin: 0 auto;
}

.flexStyle{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
main {
  max-width:1920px;
  width: 100%;
}
h1{
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  color: #302E56;
}
h2{
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #2C2A4E;
}
.swiperHome{
  position: unset;
  max-width: 1500px;
  width: 100%;
}

.blogSwiper1{
  max-width: 1800px;
  width: 100%;
}
.blogSwiper1 swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.blogSwiper1 swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiperServices1{
  position: unset;
  max-width: 1500px;
  width: 100%;
}

.swiperServices1 .swiper-wrapper{
  margin-top: 100px !important;
  position: unset;
}

.swiperServices1 .swiper-button-next{
  position: absolute;
  width: 36px;
  height: 36px;
  right: 25px;
  bottom: 50px;
  cursor: pointer;
  content:url("app/assets/images/HomeImages/ArrowRight.png")
}
.swiperServices1 .swiper-button-prev {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 25px;
  cursor: pointer;
  content:url("./app/assets/images/HomeImages/ArrowLeft.png")
}

.swiperServices2{
  position: relative;
  max-width: 1500px;
  width: 100%;
  height: 550px;
  margin: auto;
}

.swiperServices2 .swiper-wrapper{
  margin-top: 100px !important;
  position: unset;
}

.swiperServices2 .swiper-button-next{
  position: absolute;
  width: 36px;
  height: 36px;
  right:0px;
  bottom: 50px;
  cursor: pointer;
  content:url("app/assets/images/HomeImages/ArrowRight.png")
}
.swiperServices2 .swiper-button-prev {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 0px;
  bottom: 50px;
  cursor: pointer;
  content:url("./app/assets/images/HomeImages/ArrowLeft.png")
}
.swiperServices5{
  position: relative;
  max-width:1200px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  margin: auto;
}

.swiperServices5 .swiper-wrapper{
  position: unset;
}
.swiperServices5 .swiper-button-next{
  position: absolute;
  width: 36px;
  height: 36px;
  cursor: pointer;
  content:url("app/assets/images/HomeImages/ArrowRight.png")
}
.swiperServices5 .swiper-button-prev {
  width: 36px;
  height: 36px;
  position: absolute;
  left:0;
  bottom: 50px;
  cursor: pointer;
  content:url("./app/assets/images/HomeImages/ArrowLeft.png")
}

.swiperServices6{
  position: relative;
  max-width:1200px;
  width: 100%;
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
}
.swiperServices6 .swiper-wrapper{
  position: unset;
}
.swiperServices6 .swiper-button-next{
  position: absolute;
  width: 36px;
  height: 36px;
  cursor: pointer;
  content:url("app/assets/images/HomeImages/ArrowRight.png")
}

.swiperServices6 .swiper-button-prev {
  width: 36px;
  height: 36px;
  position: absolute;
  cursor: pointer;
  content:url("./app/assets/images/HomeImages/ArrowLeft.png");
}
.swiperServices7{
  position: relative;
  max-width:1700px;
  width: 100%;
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
}
.swiperHome .swiper-wrapper{
  margin-top: 100px !important;
  position: unset;
}
.swiperServices7 .swiper-wrapper{
  position: unset;
}
.swiperServices7 .swiper-button-next{
  position: absolute;
  width: 36px;
  height: 36px;
  cursor: pointer;
  content:url("app/assets/images/HomeImages/ArrowRight.png")
}

.swiperServices7 .swiper-button-prev {
  width: 36px;
  height: 36px;
  position: absolute;
  cursor: pointer;
  content:url("./app/assets/images/HomeImages/ArrowLeft.png");
}

.swiperHome .swiper-button-next{
  position: absolute;
  width: 36px;
  height: 36px;
  right: 25px;
  bottom: 50px;
  cursor: pointer;
  content:url("app/assets/images/HomeImages/ArrowRight.png")
}
.swiperHome .swiper-button-prev {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 25px;
  cursor: pointer;
  content:url("./app/assets/images/HomeImages/ArrowLeft.png")
}

.swiper-horizontal > .swiper-pagination-bullets{
  display: none;
}

.greenTitle{
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #93C8BF;
  margin-bottom: 12px;
}

.my{
  width: 100%;
  max-height: 600px;
}
.my .swiper {
  width: 100%;
  height: 100%;
}

.submitBtn{
  background:#302E56;
  border-radius: 12px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #FFFFFF;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Afrika_Regular,sans-serif !important;
  width: 100%;
}
.submitBtn:disabled{
  cursor: not-allowed;
}
.submitBtn:hover{
  box-shadow: 0px 0px 16px 0px #6B9BF2;
}
