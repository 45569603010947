.whole{
    background: rgba(249, 250, 250, 0.70);
    padding-top: 104px;
    padding-bottom: 104px;
    display: flex;
    justify-content: center;
}

.inside{
    padding: 45px 32px;
    border-radius: 16px;
    background: #FFF;
}
.titleDiv{
    display: flex;
    align-items: center;
    margin-bottom: 36px;
}

.title{
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: #22262E;
}

.blueDiv{
    display: flex;
    flex-direction: column;
    margin-left: 5px;
}
.free{
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: #2996D4;
}

.inputStyle{
    width: 100%;
    padding: 14px 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #393122;
    background: #FFFFFF;
    border-radius: 12px;
    border: 1px solid #E5E6E7;
}

.inputStyle:focus{
    outline: none;
}
.inputStyle::placeholder{
    color: #A0A2A8
}

.error{
    font-size: 14px;
    line-height: 24px;
    color: #CB2D25;
    max-width: 520px;
    width: 100%;
    margin-top: 7px;
}

.label{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 10px;
    color: #323638;
}
.invalid{
    border: 2px solid #CB2D25;
}
.inputDiv{
    margin-bottom: 40px;
}