.whole{
    background-image: url('../../../assets/images/HomeImages/Bg2.png');
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    width: 100vw;
    max-width: 100%;
    padding-top: 44px;
    padding-bottom: 48px;
}
.title{
    margin-bottom: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #93C8BF;
    text-align: center;
}
.inside{
    margin-top:80px;
    margin-bottom: 43px;
}
.inside{
    display: flex;
    justify-content: space-evenly;
}
.single{
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 251px;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
}
.lineImgDiv{
    max-width: 184px;
    height: 222px;
    width: 100%;
}

.imgDiv{
    max-width: 184px;
    height: 222px;
    width: 100%;
    position: absolute;
    left: 48px;
    top: 15px;
}

.text{
    margin-top: 24px;
    padding-bottom: 4px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color:#302E56;
    text-align: center;
}

.btnDiv{
    padding: 10px 30px;
    border-radius: 12px;
    border: 1px solid #302E56;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #302E56;
    display: flex;
    justify-content: center;
    margin: auto;
    cursor: pointer;
}

.btnDiv:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}
