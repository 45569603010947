.inside{
    width: 100%;
    display: flex;
    flex-direction: column;
   align-items: center;
}

.title{
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color:#22262E;
    margin-top: 20px;
    margin-bottom: 40px;
    max-width: 475px;
    width: 100%;
    text-align: center;
}

