.breadCrumbStyle{
    padding-top: 50px
}
.linkStyle{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #74738E;
    cursor: pointer;
}
.title{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #302E56;
}