.inside{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 72px;
    margin-bottom: 32px;
}
.left{
    padding: 32px;
    border-radius: 12px;
    background-image: url('../../../assets/images/AppFeaturesImages/AppImage1.png');
    background-repeat: no-repeat;
    /*background-size:cover;*/
    /*background-position: center;*/
    max-width: 600px;
    width: 100%;
    height: 816px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.title{
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: #302E56;
    margin-bottom: 4px;
}
.sibTitle{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #302E56;
    margin-bottom: 8px;
}
.text{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #595D67;
    margin-bottom: 32px;
    max-width: 390px;
    width: 100%;
}
.right{
    max-width: 600px;
    width: 100%;
    padding: 32px;
    border-radius: 12px;
    background-image: url('../../../assets/images/AppFeaturesImages/AppImage2.png');
    background-repeat: no-repeat;
    /*background-size:cover;*/
    /*background-position: center;*/
    height: 816px;
}
.readDiv{
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #302E56;
}
.iconDiv{
    width: 40px;
    height: 40px;
}
.readIcon{
    width: 40px;
    height: 40px;
    margin-right: 8px;
}