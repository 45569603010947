.whole{
    background-image: url('../../../assets/images/ServiceImages/Services2.png');
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    padding-top: 120px;
    padding-bottom: 120px;
    width: 100vw;
    max-width: 100%;
}
.text{
    margin-bottom: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color:#595D67;
    margin-top: 20px;
}
.containerInside{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 22px;

}
.left{
    max-width: 850px;
    width: 100%;
}
.right{
    padding-right: 70px;
}

.imgStyle{
    max-width: 472px;
    width: 100%;
}