.whole{
    background-image: url('../../../assets/images/HomeImages/Bg1.png');
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    width: 100vw;
    max-width: 100%;
    padding-top: 72px;
    padding-bottom: 180px;
}

.inside{
    border-radius: 16px;
    border: 1px solid  #E5E6E7;
    background:  #FFF;
    margin-top: 64px;
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 192px;
}

.single{
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border-right: 1px solid  #EAEBEC;
}

.singleHovered {
    margin-top: -80px;
    height: 280px;
    padding-top: 64px;
    padding-bottom: 64px;
    border-radius: 12px;
    border: 1px dashed #93C8BF;
    background: #F6FCFB;
    box-shadow: 0px 6px 20px 0px rgba(181, 227, 219, 0.30);
}

.title{
    margin-top: 20px;
    margin-bottom: 8px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #302E56;
}

.text{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #595D67;
    text-align: center;
    max-width: 250px;
}

.imgDiv{
    width: 60px;
    height: 60px;
}