.whole{
    background-image: url('../../../assets/images/ServiceImages/Services1.png');
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    height: 900px;
    width: 100vw;
    max-width: 100%;
    display: flex;
}

.leftPart{
    margin-top: 250px;
    max-width: 478px;
    width: 100%;
}

.servicesText{
    margin-top: 12px;
    margin-bottom: 32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #74738E;
}
.servicesBlocks{
    /*margin-top: 180px;*/
    display: flex;
    align-items: center;
    width: 100%;
}
.block{
    padding: 16px;
    background-color: #FFF;
    width: 100%;
    cursor: pointer;
}
.blockTitle{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 8px;
    color: #302E56;
}
.blockText{
    max-width: 265px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #595D67;
}
.activeBlocksInside{
    border-right:none
}
.blocksInside{
    border-right: 2px solid #EEEEEF;
}
.block:last-child .blocksInside{
    border-right: none;
}
.activeBlock{
    width: 100%;
    background-color: #D4EFEA;
    cursor: pointer;
    padding: 16px;
    border-bottom:2px solid #93C8BF;
}

.sliderDiv{
    display: flex;
    align-items: center;
}

.sliderRight{

}

.text{
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #595D67;
}