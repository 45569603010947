.title{
    margin-bottom: 16px;
    margin-top: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #22213D;
}
.imgStyle{
    border-radius: 16px;
    max-width: 800px;
    width: 100%;
    margin-bottom: 20px;
}
